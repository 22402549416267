<template>
  <section>
    <hero-global title="CERMIs" subtitle="CERMIs Autonómicos" first-width="60" last-width="30" img="/img/autonomic-cermi/autonomic-cermi_1.svg" />
  </section>

  <section class="p-y-2-rem menu-anclas container">
    <router-link :class="[this.$route?.params?.slug === 'sobre-cermis-autonomicos' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" to="#sobre-cermis-autonomicos" @click="scrollSection('sobre-cermis-autonomicos')">Sobre CERMIs Autonómicos
    </router-link>
    <router-link :class="[this.$route?.params?.slug === 'directorios-cermi' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" to="#directorios-cermi" @click="scrollSection('directorios-cermi')">Directorio de CERMIS
    </router-link>
     <a target="_blank" href="http://www.estadisticasocial.es/cermi.php" class="btn btn-tertiary" title="Ir a Comparador Cermis Autonómicos ">Comparador Cermis Autonómicos</a>
  </section>
  <section id="sobre-cermis-autonomicos" class="container">
    <div class="text-center">
      <h2 class="title-grand title__double-center" data-text="Sobre CERMIs Autonómicos">Sobre CERMIs Autonómicos</h2>
    </div>
    <div class="grid-full">
      <div class="grid right">
        <!-- <ribbon text="CERMIs Autonómicos" /> -->
        <div class="m-top-2-rem">
          <iframe class="d-block w-100" height="280" frameborder="0" src="https://www.youtube.com/embed/kECnc_lQylY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <article class="grid m-top-2-rem" v-html="$t('easyReader.cermis_autonomicos.text.'+this.store.easyRead.active)"></article>
    </div>

  </section>

  <section class="directory" id="directorios-cermi">
    <div class="container" v-if="dataOrganizations != 0 && dataOrganizations">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Directorio de CERMIs">Directorio de CERMIs</h2>
      </div>
      <div class="box-content container">
        <div class="content container shadow">
          <ul class="list-item">
            <li v-for="(item, key ) in dataOrganizations" :key="key">
              <input type="checkbox" name="list" :id="item.dataId" />
              <label :for="item.dataId">
                <img :src="item.logo.url" :alt="'Imagen de '+item.name" v-if="item.logo.url">
                <span> {{item.name}}</span>
              </label>
              <div class="inner-container">
                <p v-if="item.address" class="category-location item-list">{{item.address}}</p>

                <div class="category-telephone item-list" v-if="item.phone">
                  <span>
                    Teléfono: <a :href="'tel:'+item.phone" :title="'Llamar a '+item.phone">{{item.phone}}</a>
                  </span>
                  <span v-if="item.fax">
                    Fax: {{item.fax}}
                  </span>
                </div>
                <a v-if="item.web" class="category-web item-list" :href="item.web" target="_blank" :title="'Ir al '+item.web">{{item.web}}</a>
                <a v-if="item.email" :href="'mailto: '+item.email" class="category-email item-list" :title="'Mandar un emali a '+item.email">{{item.email}}</a>
              </div>
            </li>

          </ul>

        </div>
      </div>
    </div>
  </section>
  <carrousel2 class="container" />
  <section>
    <other-links-interest class="links-interest-box container" />
  </section>
  <br />
  <br />

</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  // import Ribbon from "@/components/Global/Ribbon";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { contentStore } from '@/stores/contents'
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";

  export default {
    name: "AutonomicCermi",
    components: {
      OtherLinksInterest,
      Carrousel2,
      // Ribbon, 
      HeroGlobal
    },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.store.loadOrganizations({ catId: 3 })
      if (this.$route.hash) {
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(element).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
    },
    props: ['slug', 'id'],
    data: () => ({

    }),
    methods: {
      scrollSection: function (params) {
        if (params) {
          const offsetTop = document.getElementById(params).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
      }
    },
    computed: {
      dataOrganizations() {
        if (this.store.organizations[3]) {
          return this.store.organizations[3].items
        }
        return null
      },
    },

  }
</script>

<style scoped lang="scss">
  @import "../styles/colours";

  .organizations {
    .hero-content {
      height: 320px;
    }
  }

  .directory {
    background: #8baddc26;
    padding: 60px 0;
    margin: 60px 0;

    h2 {
      color: #8baddc66;
    }

    .box-content {
      background: transparent;

      .menu-list {
        min-height: 100% !important;
      }
    }
  }

  #directorios-cermi {
    .box-content .content {
      width: 90%;
      padding: 40px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
</style>